/* montserrat-100 - latin_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/montserrat-v15-latin_cyrillic-100.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/montserrat-v15-latin_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/montserrat-v15-latin_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-100.woff') format('woff'), /* Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
  }
  /* montserrat-regular - latin_cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/montserrat-v15-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/montserrat-v15-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/montserrat-v15-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  }
  /* montserrat-500 - latin_cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/montserrat-v15-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/montserrat-v15-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/montserrat-v15-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  }
  /* montserrat-600 - latin_cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/montserrat-v15-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/montserrat-v15-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/montserrat-v15-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  }
  /* montserrat-700 - latin_cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/montserrat-v15-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/montserrat-v15-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/montserrat-v15-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  }
  /* montserrat-800 - latin_cyrillic */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/montserrat-v15-latin_cyrillic-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('/fonts/montserrat-v15-latin_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('/fonts/montserrat-v15-latin_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-800.woff') format('woff'), /* Modern Browsers */
         url('/fonts/montserrat-v15-latin_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
  }