footer {
    position: relative;
    background: rgba(47, 128, 237, 0.7);
    z-index: 99;
}

.footer {
    display: flex;
    padding: 25px;

    @media screen and (min-width: 1640px) {
        padding: 27px 82px;
    }

    @media screen and (min-width: 1440px) {
        padding: 27px 31px;
    }

    @media screen and (min-width: 1024px) {
        padding: 27px 20px;
    }

    @media screen and (min-width: 320px) {
        padding: 17px 20px;
    }

    .menu-footer {
        display: flex;
    }

    .menu-footer__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style-type: none;

        &:not(:last-child) {
            margin-right: 125px;
        }

        @media screen and (min-width: 1440px) {
            &:not(:last-child) {
                margin-right: 139px;
            }
        }

        @media screen and (min-width: 1024px) {
            &:not(:last-child) {
                margin-right: 90px;
            }
        }

        @media screen and (min-width: 768px) {
            &:not(:last-child) {
                margin-right: 70px;
            }
        }

        @media screen and (min-width: 320px) {
            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }

    .menu-footer__title {
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        text-transform: uppercase;

        @media screen and (min-width: 1440px) {
            margin-bottom: 20px;
        }

        @media screen and (min-width: 1024px) {
            font-size: 16px;
        }

        @media screen and (min-width: 768px) {
            font-size: 14px;
        }

        @media screen and (min-width: 320px) {
            font-size: 12px;
        }
    }

    .menu-footer__item {
        @media screen and (min-width: 1440px) {
            &:not(:last-child) {
                margin-bottom: 13px;
            }
        }

        @media screen and (min-width: 1024px) {
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    .menu-footer__link {
        font-size: 16px;
        color: #FFFFFF;
        text-decoration: none;

        @media screen and (min-width: 1024px) {
            font-size: 16px;
        }

        @media screen and (min-width: 768px) {
            font-size: 14px;
        }

        @media screen and (min-width: 320px) {
            font-size: 12px;
        }
    }
}