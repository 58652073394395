$gray: #a8a8a8;
$gray-middle: #636369;
$black: #252525;
$substrate: #F0F0F3;
$blue: #39C3FF;
$violet: #2F80ED;
$green: #548C78;

// keyframes

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotation-ease {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes right-ease {
    100% {
        left: 105px;
    }
}

@keyframes left-ease {
    100% {
        left: 6px;
    }
}

