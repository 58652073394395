header {
    position: fixed;
    width: 100%;
    box-shadow: 0px 1px 20px 20px $substrate;
    backdrop-filter: blur(10px);
    background-color: $substrate;
    -webkit-backdrop-filter: blur(10px);
    z-index: 9999;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 23px;

    @media screen and (min-width: 768px) {
        flex-direction: row !important;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
    }
}

.hamburger {
    display: none;
    position: absolute;
    padding: 0;
    top: 13px;
    left: 10px;
    width: 40px;
    height: 40px;
    background: #F0F0F3;
    border: none;
    box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px rgba(174, 174, 192, 0.5);
    border-radius: 5px;
    transition: .3s ease all;
    z-index: 999999;

    &:focus {
        box-shadow: inset -5px -5px 15px rgba(255, 255, 255, 0.7), inset 5px 5px 15px rgba(174, 174, 192, 0.4);
    }

    @media screen and (min-width: 768px) {
        display: none !important;
    }

    @media screen and (min-width: 320px) and (max-width: 768px) {
        display: block;
    }

    .hamburger__line {
        position: absolute;
        left: 50%;
        width: 20px;
        height: 1px;
        background-color: #636369;
        transform: translateX(-50%);

        &:first-child {
            top: 12px;
        }

        &:nth-child(2) {
            top: 19px;
        }

        &:last-child {
            top: 26px;
        }
    }

    &.hamburger--open {
        animation: right-ease .5s 1 ease-in-out forwards;

        .hamburger__line {
            position: absolute;
            left: 50%;
            width: 20px;
            height: 1px;
            background-color: #636369;
            transform: translateX(-50%);
    
            &:first-child {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
    
            &:nth-child(2) {
                display: none;
            }
    
            &:last-child {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}

.logotype {
    @media screen and (min-width: 768px) {
        display: block !important;
        margin-left: 8px;
    }

    @media screen and (min-width: 320px) and (max-width: 768px) {
        display: none;
    }
}

.logotype__logo {
    width: 104px;

    @media screen and (min-width: 1440px) {
        width: 105px;
    }

    @media screen and (min-width: 1024px) {
        width: 77px;
    }

    @media screen and (min-width: 768px) {
        width: 67px;
    }
}

.menu {
    display: flex;
    flex-grow: 1;
    margin-left: 30px;

    @media screen and (min-width: 1440px) {
        margin-left: 33px;
    }

    @media screen and (min-width: 1024px) {
        margin-left: 27px;
    }

    @media screen and (min-width: 768px) {
        margin-left: 14px;
    }

    @media screen and (min-width: 320px) and (max-width: 767px) {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        padding: 80px 20px 24px;
        top: 0;
        left: 0;
        width: 50%;
        height: 270px;
        background: #F0F0F3;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        z-index: 9999;

        &.menu--close {
            display: none;
        }
    }

    .logotype {
        display: none;

        @media screen and (min-width: 768px) {
            display: none !important;
        }

        @media screen and (min-width: 320px) {
            display: flex !important;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            width: 100%;
        }
    }

    .logotype__logo {
        @media screen and (max-width: 768px) {
            width: 70px;
        }
    }
    
    .menu__list {
        display: flex;
        align-items: center;
        list-style-type: none;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }

        @media screen and (min-width: 320px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .menu__item {
        &:not(:last-child) {
            margin-right: 70px;
        }

        @media screen and (min-width: 1440px) {
            &:not(:last-child) {
                margin-right: 50px;
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: 1024px) {
            &:not(:last-child) {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: 768px) {
            &:not(:last-child) {
                margin-right: 30px;
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: 320px) {
            &:not(:last-child) {
                margin: 0;
                margin-bottom: 15px;
            }
        }
    }

    .menu__link {
        font-size: 18px;
        line-height: 22px;
        color: #636369;
        text-decoration: none;
        padding: 10px;
        border-radius: 5px;
        transition: .3s ease all;

        @media screen and (min-width: 1024px) {
            font-size: 18px;
        }

        @media screen and (min-width: 768px) {
            font-size: 16px;
        }

        &:hover {
            background: #F0F0F3;
            box-shadow: -7px -7px 20px #FFFFFF, 7px 7px 20px rgba(174, 174, 192, 0.5);
        }

        &:focus {
            box-shadow: inset -10px -10px 15px rgba(255, 255, 255, 0.7), inset 10px 10px 15px rgba(174, 174, 192, 0.3);
        }
    }
}

.change-theme {
    position: absolute;
    right: 319px;
    width: 60px;
    height: 145px;
    background: #F0F0F3;
    border: none;
    box-shadow: -3px -3px 10px #ffffff, 3px 3px 10px rgba(174, 174, 192, 0.5);
    border-radius: 0 0 30px 30px;
    transform: translateY(-20%);
    transition: .5s ease all;

    @media screen and (min-width: 1640px) {
        right: 421px;
    }

    @media screen and (min-width: 1440px) {
        top: -20px;
        right: 282px;
    }

    @media screen and (min-width: 1024px) {
        top: -26px;
        right: 196px;
        width: 58px;
    }

    @media screen and (min-width: 768px) {
        top: -49px;
    }

    @media screen and (min-width: 320px) {
        top: -60px;
        right: 123px;
        width: 37px;
    }

    svg {
        position: absolute;
        bottom: 31px;
        left: 19px;
        width: 25px;
        height: 27px;
        transition: .5s ease all;

        @media screen and (min-width: 1024px) {
            bottom: 33px;
            left: 17px;
            width: 22px;
            height: 23px;
        }

        @media screen and (min-width: 768px) {
            bottom: 20px;
            left: 13px;
            width: 15px;
            height: 16px;
        }

        @media screen and (min-width: 320px) {
            bottom: 19px;
            left: 10.5px;
            width: 15px;
            height: 15px;
        }
    }

    .sun {
        display: none;
        width: 30px;
        height: 30px;
        left: 16px;

        @media screen and (min-width: 1024px) {
            bottom: 31px;
            left: 14px;
            width: 30px;
            height: 30px;
        }

        @media screen and (min-width: 768px) {
            bottom: 16px;
            left: 9.5px;
        }

        @media screen and (min-width: 320px) {
            bottom: 16px;
            left: 9.5px;
            width: 20px;
            height: 20px;
        }
    }

    &:hover {
        box-shadow: inset -10px -10px 10px rgba(255, 255, 255, 0.7), inset 10px 10px 10px rgba(174, 174, 192, 0.3);
        top: -15px;

        @media screen and (min-width: 1440px) {
            top: -15px;
        }
    
        @media screen and (min-width: 1024px) {
            top: -21px;
        }
    
        @media screen and (min-width: 768px) {
            top: -44px;
        }
    
        @media screen and (min-width: 320px) {
            top: -55px;
        }

        svg {
            transform: scale(1.1);
        }
    }
}

.accessibility {
    position: absolute;
    right: 228px;
    width: 60px;
    height: 145px;
    background: #F0F0F3;
    border: none;
    box-shadow: -3px -3px 10px #ffffff, 3px 3px 10px rgba(174, 174, 192, 0.5);
    border-radius: 0 0 30px 30px;
    transform: translateY(-20%);
    transition: .5s ease all;

    @media screen and (min-width: 1640px) {
        right: 332px;
    }

    @media screen and (min-width: 1440px) {
        top: -20px;
        right: 192px;
    }

    @media screen and (min-width: 1024px) {
        top: -26px;
        right: 106px;
        width: 58px;
    }

    @media screen and (min-width: 768px) {
        top: -49px;
        right: 62px;
    }

    @media screen and (min-width: 320px) {
        top: -60px;
        right: 63px;
        width: 37px;
    }

    svg {
        position: absolute;
        bottom: 31px;
        left: 7.5px;
        width: 44px;
        height: 22px;
        transition: .5s ease all;

        @media screen and (min-width: 1024px) {
            bottom: 34px;
            left: 6.5px;
            width: 44px;
            height: 22px;
        }

        @media screen and (min-width: 768px) {
            bottom: 20px;
            left: 3.5px;
            width: 29px;
            height: 14px;
        }

        @media screen and (min-width: 320px) {
            bottom: 19px;
            left: 2.5px;
            width: 28px;
            height: 14px;
        }
    }

    .eye-big {
        display: none;
    }

    .eye-black {
        display: none;
    }

    .eye-black-big {
        display: none;
    }

    &:hover {
        box-shadow: inset -10px -10px 10px rgba(255, 255, 255, 0.7), inset 10px 10px 10px rgba(174, 174, 192, 0.3);
        top: -15px;

        @media screen and (min-width: 1440px) {
            top: -15px;
        }
    
        @media screen and (min-width: 1024px) {
            top: -21px;
        }
    
        @media screen and (min-width: 768px) {
            top: -44px;
        }
    
        @media screen and (min-width: 320px) {
            top: -55px;
        }

        .eye {
            display: none;
        }

        .eye-big {
            display: block;
        }
    }
}