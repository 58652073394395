body {
    position: relative;
    background: $substrate;
    font-family: 'Montserrat', sans-serif;
    color: $gray;
    overflow-x: hidden;
    counter-reset: section;
    overflow-x: hidden;
    transition: .3s ease all;
}

h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    outline: none;
}

main {
    position: relative;
    padding-top: 70px;
}

.wrapper {
    margin: 0 120px;

    @media screen and (min-width: 1640px) {
        margin: 0 275px;
    }

    @media screen and (min-width: 1440px) {
        margin: 0 125px;
    }

    @media screen and (min-width: 1024px) {
        margin: 0 50px;
    }

    @media screen and (min-width: 768px) {
        margin: 0 40px;
    }

    @media screen and (min-width: 320px) {
        margin: 0 30px;
    }
}

.inside-wrapper {
    padding: 0 200px;
    margin: 100px 0;
}

.container {
    position: relative;
    margin: 0 auto;

    @media screen and (min-width: 1640px) {
        width: 1322px;
    }

    @media screen and (min-width: 1440px) {
        width: 1044px;
    }

    @media screen and (min-width: 1024px) {
        width: 805px;
    }

    @media screen and (min-width: 768px) {
        width: 682px;
    }

    @media screen and (min-width: 320px) {
        width: 320px;
    }
}

.breadcrumbs {
    margin: 70px 0;

    @media screen and (min-width: 1440px) {
        margin: 70px 0;
    }

    @media screen and (min-width: 768px) {
        margin: 45px 0;
    }

    @media screen and (min-width: 320px) {
        margin: 30px 10px;
    }

    .breadcrumbs__list {
        display: flex;
        align-items: center;
        list-style-type: none;
    }

    .breadcrumbs__item {
        position: relative;

        &:not(:last-child) {
            padding-right: 30px;

            &:before {
                content: "";
                position: absolute;
                top: 6px;
                right: 12px;
                width: 8px;
                height: 1px;
                background-color: $gray-middle;
                transform: rotate(45deg);
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 6px;
                right: 12px;
                width: 8px;
                height: 1px;
                background-color: $gray-middle;
                transform: rotate(-45deg);
            }
        }
    
        @media screen and (min-width: 768px) {
            &:not(:last-child) {
                &:before {
                    top: 6px;
                    right: 12px;
                    width: 8px;
                    height: 1px;
                }

                &:after {
                    bottom: 6px;
                    right: 12px;
                    width: 8px;
                    height: 1px;
                }
            }
        }
    
        @media screen and (min-width: 320px) {
            &:not(:last-child) {
                &:before {
                    top: 7px;
                    right: 12px;
                    width: 5px;
                    height: 1px;
                }

                &:after {
                    bottom: 7px;
                    right: 12px;
                    width: 5px;
                    height: 1px;
                }
            }
        }
    }

    .breadcrumbs__link {
        font-size: 16px;
        color: $gray-middle;
        text-decoration: none;

        @media screen and (min-width: 1440px) {
            font-size: 16px;
        }

        @media screen and (min-width: 768px) {
            font-size: 14px;
        }

        @media screen and (min-width: 320px) {
            font-size: 12px;
        }
    }
}

.section--down-right-line {
    padding-bottom: 200px;

    @media screen and (min-width: 1024px) {
        padding-bottom: 213px;
    }

    @media screen and (min-width: 768px) {
        padding-bottom: 138px;
    }

    @media screen and (min-width: 320px) {
        padding-bottom: 60px;
    }
}

.section--down-left-line {
    padding-bottom: 200px;

    @media screen and (min-width: 320px) and (max-width: 768px) {
        padding-bottom: 60px;
    }
}

.headline-primary {
    margin: 118px 0;
    margin-left: 131px;
    margin-bottom: 90px;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #636369;

    br {
        display: none;
    }

    @media screen and (min-width: 1640px) {
        margin: 126px 0;
        margin-bottom: -50px;
        text-align: start;

        br {
            display: none;
        }
    }

    @media screen and (min-width: 1024px) {
        margin: 132px 0;
        margin-left: 0px;
        margin-bottom: -50px;
        font-size: 24px;

        br {
            display: none;
        }
    }

    @media screen and (min-width: 768px) {
        padding-bottom: 142px;
        font-size: 20px;

        br {
            display: none;
        }
    }

    @media screen and (min-width: 320px) {
        padding-bottom: 100px;
        margin: 56px 0;
        margin-left: 0px;
        margin-bottom: -74px;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;

        br {
            display: block;
        }
    }
}

.button-wrapper {
    display: flex;
    align-items: center;
    margin-top: 40px;

    @media screen and (min-width: 768px) {
        margin-top: 30px;
    }

    @media screen and (min-width: 320px) {
        margin-top: 20px;
    }

    &.button-wrapper--left {
        justify-content: flex-start;
    }

    &.button-wrapper--center {
        justify-content: center;
    }

    &.button-wrapper--right {
        justify-content: flex-end;
    }
}

.button {
    padding: 17px 30px;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border: none;
    border-radius: 20px;
    transition: .3s ease all;

    @media screen and (min-width: 1440px) {
        padding: 14px 30px;
        font-size: 22px;
    }

    @media screen and (min-width: 1024px) {
        padding: 12px 25px;
        font-size: 18px;
    }

    @media screen and (min-width: 768px) {
        padding: 10px 20px;
        font-size: 16px;
    }

    @media screen and (min-width: 320px) {
        padding: 5px 15px;
        font-size: 12px;
    }

    &.button--primary {
        color: #FFFFFF;
        background: rgba(47, 128, 237, 0.5);
    }

    &:hover {
        backdrop-filter: brightness(0.5);
        transform: scale(1.03);
    }

    &:focus {
        backdrop-filter: brightness(0.3);
        transform: scale(.95);
    }
}

.formRight {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    @media screen and (min-width: 1440px) {
        top: 0;
        right: -200px;
        width: 658px;
        height: 635px;
        transform: unset;
    }

    @media screen and (min-width: 1024px) {
        top: 0;
        right: -207px;
        width: 473px;
        height: 457px;
        transform: rotate(5deg);
    }

    @media screen and (min-width: 768px) {
        top: 62px;
        right: -245px;
        width: 473px;
        height: 457px;
    }

    @media screen and (min-width: 320px) {
        top: 31px;
        right: -100px;
        width: 188px;
        height: 213px;
        transform: rotate(28deg);
    }
}

.formLeft {
    position: absolute;
    top: 280px;
    left: 0;
    z-index: 99;

    @media screen and (min-width: 1640px) {
        top: 231px;
    }

    @media screen and (min-width: 1440px) {
        top: 365px;
        left: -124px;
        width: 566px;
        height: 551px;
        transform: rotate(-7deg) !important;
    }

    @media screen and (min-width: 1024px) {
        top: 453px;
        left: -124px;
        width: 429px;
        height: 418px;
        transform: unset !important;
    }

    @media screen and (min-width: 768px) {
        top: 647px;
        left: -71px;
        width: 368px;
        height: 359px;
        transform: rotate(-12deg);
    }

    @media screen and (min-width: 320px) {
        top: 257px;
        left: -49px;
        width: 188px;
        height: 213px;
    }
}

.formLeftTwo {
    position: absolute;
    top: 2431px;
    left: -64px;
    z-index: 99;

    @media screen and (min-width: 1640px) {
        top: 2486px;
        left: -195px;
    }

    @media screen and (min-width: 1440px) {
        top: 2438px;
        left: -260px;
        width: 734px;
        height: 714px;
    }

    @media screen and (min-width: 1024px) {
        top: 2492px;
        left: -29px;
        width: 235px;
        height: 575px;
    }

    @media screen and (min-width: 768px) {
        top: 2492px;
        left: -29px;
        width: 176px;
        height: 430px;
    }

    @media screen and (min-width: 320px) {
        top: 1874px;
        left: 0;
        width: 72px;
        height: 177px;
    }
}

.formRightTwo {
    position: absolute;
    bottom: -140px;
    right: 0;
    z-index: 99;

    @media screen and (min-width: 1440px) {
        bottom: -142px;
        right: -220px;
        width: 763px;
        height: 494px;
        object-fit: cover;
    }

    @media screen and (min-width: 1024px) {
        bottom: -124px;
        right: -220px;
        width: 763px;
        height: 332px;
    }

    @media screen and (min-width: 768px) {
        bottom: -99px;
        right: -100px;
        width: 457px;
        height: 189px;
    }

    @media screen and (min-width: 320px) {
        bottom: -99px;
        right: -71px;
        width: 230px;
        height: 260px;
    }
}

.formBlack {
    display: none;
}

.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 150px;
    padding: 45px 100px;
    background: $substrate;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.5);
    border-radius: 20px;

    @media screen and (min-width: 1440px) {
        margin-top: 60px;
        padding: 40px 100px;
    }

    @media screen and (min-width: 1024px) {
        margin-top: 60px;
        padding: 40px 80px;
    }

    @media screen and (min-width: 768px) {
        margin-top: 60px;
        padding: 40px 70px;
    }

    @media screen and (min-width: 320px) {
        margin-top: 60px;
        padding: 21px 25px;
    }

    .form__title {
        margin: 0;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #636369;

        @media screen and (min-width: 1024px) {
            font-size: 24px;
        }

        @media screen and (min-width: 768px) {
            font-size: 20px;
        }

        @media screen and (min-width: 320px) {
            font-size: 12px;
        }
    }

    .form__text {
        font-size: 18px;
        letter-spacing: 0.05em;
        text-transform: lowercase;
        color: #636369;

        @media screen and (min-width: 768px) {
            font-size: 18px;
            text-align: center;
        }

        @media screen and (min-width: 320px) {
            font-size: 12px;
            text-align: center;
        }
    }

    .form__content {
        width: 100%;
        margin-top: 50px;

        @media screen and (min-width: 320px) and (max-width: 768px) {
            margin-top: 30px;
        }
    }
}

.input-container {
    display: block;
    position: relative;
    width: 100%;

    input {
        width: 100%;
        padding: 17px 19px;
        font-size: 18px;
        background-color: transparent;
        border: 1px solid $violet;
        filter: drop-shadow(0px 0px 7px $violet);
        border-radius: 20px;
        transition: .3s ease all;

        @media screen and (min-width: 1440px) {
            padding: 19px 20px;
            border-radius: 20px;
            font-size: 18px;
        }

        @media screen and (min-width: 1024px) {
            padding: 19px 20px;
            border-radius: 20px;
            font-size: 16px;
        }

        @media screen and (min-width: 768px) {
            padding: 16px 18px;
            border-radius: 20px;
            font-size: 14px;
        }

        @media screen and (min-width: 320px) {
            padding: 9px 14px;
            border-radius: 10px;
            font-size: 12px;
        }

        +.title-input {
            position: absolute;
            top: 50%;
            left: 20px;
            font-size: 18px;
            letter-spacing: 0.05em;
            color: #636369;
            transform: translateY(-50%);
            transition: .3s ease all;

            @media screen and (min-width: 1440px) {
                font-size: 18px;
            }

            @media screen and (min-width: 1024px) {
                font-size: 16px;
            }

            @media screen and (min-width: 768px) {
                font-size: 14px;
            }

            @media screen and (min-width: 320px) {
                font-size: 12px;
            }
        }

        ~.error {
            display: none;
            position: absolute;
            left: 25px;
            bottom: -17px;
            font-size: 12px;
            color: #BD1D1D;
            transition: .3s ease all;

            @media screen and (min-width: 1440px) {
                bottom: -22px;
                left: 20px;
                font-size: 14px;
            }

            @media screen and (min-width: 1024px) {
                left: 20px;
                font-size: 12px;
            }

            @media screen and (min-width: 768px) {
                left: 20px;
                font-size: 10px;
            }

            @media screen and (min-width: 320px) {
                left: 20px;
                font-size: 8px;
            }

            &:before {
                content: "*";
                position: absolute;
                top: 50%;
                left: -8px;
                color: #BD1D1D;
                transform: translateY(-50%);
                transition: .3s ease all;
            }
        }

        &:hover {
            filter: drop-shadow(0px 0px 3px $violet);
        }

        &:focus {
            +.title-input {
                padding: 4px;
                top: -1px;
                left: 25px;
                background-color: $substrate;
                font-size: 12px;
                border: 1px solid #636369;
                border-radius: 10px;

                @media screen and (min-width: 1024px) {
                    font-size: 12px;
                }

                @media screen and (min-width: 768px) {
                    font-size: 10px;
                }

                @media screen and (min-width: 320px) {
                    font-size: 8px;
                }
            }
        }

        &:not(:placeholder-shown) {
            +.title-input {
                padding: 4px;
                top: -1px;
                left: 25px;
                background-color: $substrate;
                font-size: 12px;
                border: 1px solid #636369;
                border-radius: 10px;
            }

            @media screen and (min-width: 1024px) {
                +.title-input {
                    font-size: 12px;
                }
            }

            @media screen and (min-width: 768px) {
                +.title-input {
                    font-size: 10px;
                }
            }

            @media screen and (min-width: 320px) {
                +.title-input {
                    font-size: 8px;
                }
            }
        }

        &:invalid {
            ~.error {
                display: block;
            }
        }
    }

    &:not(:last-child) {
        margin-bottom: 50px;
    }
}

.textarea-container {
    display: block;
    position: relative;

    .textarea {
        position: relative;
        width: 100%;
        height: 130px;
        padding: 17px 19px;
        font-size: 18px;
        background-color: transparent;
        border: 1px solid $violet;
        filter: drop-shadow(0px 0px 7px $violet);
        border-radius: 20px;
        transition: .3s ease all;

        @media screen and (min-width: 1440px) {
            padding: 19px 20px;
            height: 115px;
            padding-top: 16px;
            border-radius: 20px;
            font-size: 18px;
        }

        @media screen and (min-width: 1024px) {
            padding: 19px 20px;
            height: 100px;
            padding-top: 16px;
            border-radius: 20px;
            font-size: 16px;
        }

        @media screen and (min-width: 768px) {
            padding: 16px 18px;
            height: 70px;
            padding-top: 16px;
            border-radius: 20px;
            font-size: 14px;
        }

        @media screen and (min-width: 320px) {
            padding: 9px 14px;
            height: 70px;
            padding-top: 16px;
            border-radius: 10px;
            font-size: 12px;
        }

        +.title-input {
            position: absolute;
            top: 14px;
            left: 20px;
            font-size: 18px;
            letter-spacing: 0.05em;
            color: #636369;
            transition: .3s ease all;

            @media screen and (min-width: 1440px) {
                font-size: 18px;
            }

            @media screen and (min-width: 1024px) {
                top: 19px;
                font-size: 16px;
            }

            @media screen and (min-width: 768px) {
                font-size: 14px;
            }

            @media screen and (min-width: 320px) {
                font-size: 12px;
            }
        }

        &:hover {
            filter: drop-shadow(0px 0px 3px $violet);
        }

        &:focus {
            +.title-input {
                padding: 4px;
                top: -10px;
                left: 25px;
                background-color: $substrate;
                font-size: 12px;
                border: 1px solid #636369;
                border-radius: 10px;

                @media screen and (min-width: 1024px) {
                    font-size: 12px;
                }

                @media screen and (min-width: 768px) {
                    font-size: 10px;
                }

                @media screen and (min-width: 320px) {
                    font-size: 8px;
                }
            }
        }

        &:not(:placeholder-shown) {
            +.title-input {
                padding: 4px;
                top: -10px;
                left: 25px;
                background-color: $substrate;
                font-size: 12px;
                border: 1px solid #636369;
                border-radius: 10px;
            }

            @media screen and (min-width: 1024px) {
                +.title-input {
                    font-size: 12px;
                }
            }

            @media screen and (min-width: 768px) {
                +.title-input {
                    font-size: 10px;
                }
            }

            @media screen and (min-width: 320px) {
                +.title-input {
                    font-size: 8px;
                }
            }
        }
    }
}

.it2b-logo {
    object-fit: cover;

    @media screen and (min-width: 1440px) {
        width: 700px;
        height: 331px;
    }

    @media screen and (min-width: 1024px) {
        width: 605px;
        height: 297px;
    }

    @media screen and (min-width: 768px) {
        width: 539px;
        height: 265px;
    }

    @media screen and (min-width: 320px) {
        width: 259px;
        height: 127px;
    }
}

.it2b-logo--black {
    display: none;
}

.dev {
    position: relative;
    width: 100%;
    object-fit: contain;
}

.languages {
    position: relative;
    margin-right: 400px;

    @media screen and (min-width: 1640px) {
        margin-right: 500px;
    }

    @media screen and (min-width: 1440px) {
        margin-right: 350px;
    }

    @media screen and (min-width: 1024px) {
        margin-right: 260px;
    }

    @media screen and (min-width: 768px) {
        margin-right: 165px;
    }

    @media screen and (min-width: 320px) {
        margin-right: 95px;
    }
}

.language__list {
    width: 70px;

    .language__item {
        display: block;

        &.language__item--en {
            .language__link {
                color: $blue;
            }
        }

        &.language__item--il {
            .language__link {
                color: $violet;
            }
        }

        &.language__item--ar {
            .language__link {
                color: $green;
            }
        }
    }

    .language__link {
        font-size: 14px;
        color: slategrey;
        text-decoration: none;
    }
    
    &.close {
        display: none;
        transition: .3s ease all;
    }
}

.greeting-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 170px;
    padding-bottom: 310px;

    @media screen and (min-width: 1640px) {
        margin-top: 230px;
        padding-bottom: 142px;
    }

    @media screen and (min-width: 1440px) {
        margin-top: 283px;
        padding-bottom: 190px;
    }

    @media screen and (min-width: 1024px) {
        margin-top: 298px;
        padding-bottom: 158px;
    }

    @media screen and (min-width: 768px) {
        margin-top: 357px;
        padding-bottom: 322px;
    }

    @media screen and (min-width: 320px) {
        margin-top: 29px;
        padding-bottom: 178px;
    }

    .img-circle-i {
        position: absolute;
        top: -122px;
        left: 128px;

        @media screen and (min-width: 1640px) {
            top: -120px;
            left: 166px;
        }

        @media screen and (min-width: 1440px) {
            top: -103px;
            left: 46px;
        }

        @media screen and (min-width: 1024px) {
            top: -160px;
            left: -51px;
        }

        @media screen and (min-width: 768px) {
            display: block;
            top: -200px;
            left: -53px;
        }
    
        @media screen and (min-width: 320px) {
            display: none !important;
        }
    }
}

.img-circle-i--black {
    display: none !important;
}

.block-show-portfolio {
    position: absolute;
    width: 225px;
    height: 225px;
    right: -97px;
    bottom: 0;

    @media screen and (min-width: 320px) {
        bottom: -145px;
    }
}

.show-portfolio {
    position: relative;
    width: 100px;
    height: 100px;
    border: none;
    background: $substrate;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
    border-radius: 100px;
    transition: .3s ease all;
    z-index: 99;

    @media screen and (min-width: 1640px) {
        right: 276px;
        bottom: 259px;
    }

    @media screen and (min-width: 1440px) {
        right: 133px;
        bottom: 253px;
    }

    @media screen and (min-width: 1024px) {
        position: absolute;
        right: 110px;
        bottom: 123px;
        width: 100px;
        height: 100px;
    }

    @media screen and (min-width: 768px) {
        position: absolute;
        right: 137px;
        bottom: 235px;
        width: 90px;
        height: 90px;
    }

    @media screen and (min-width: 320px) {
        position: absolute;
        right: 145px;
        bottom: 179px;
        width: 60px;
        height: 60px;
    }

    .text {
        &:before {
            content: "";
            position: absolute;
            width: 225px;
            height: 225px;
            top: -64px;
            left: -62px;
            background-image: url("/img/svg/text-show-portfolio.svg");
            background-repeat: no-repeat;
            background-size: contain;
            animation: rotation 25s infinite linear;
        }

        @media screen and (min-width: 1024px) {
            &:before {
                width: 221px;
                height: 221px;
                top: -60px;
                left: -60px;
            }
        }

        @media screen and (min-width: 768px) {
            &:before {
                width: 218px;
                height: 218px;
                top: -62px;
                left: -63px;
            }
        }

        @media screen and (min-width: 320px) {
            &:before {
                width: 130px;
                height: 130px;
                top: -34px;
                left: -34px;
            }
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 8px);
        width: 0; 
        height: 0; 
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 24px solid $substrate;;
        filter: drop-shadow(0 0 4px $violet);
        transform: translateY(-50%);
        transition: .3s ease all;

        @media screen and (min-width: 1024px) {
            left: calc(50% - 8px);
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 23px solid $substrate;
        }

        @media screen and (min-width: 768px) {
            left: calc(50% - 5px);
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 20px solid $substrate;
        }

        @media screen and (min-width: 320px) {
            left: calc(50% - 5px);
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 16px solid $substrate;
        }
    }

    &:hover {
        &:after {
            transform: translateY(-50%) scale(1.1);
        } 
    }

    &:focus {
        box-shadow: inset -10px -10px 10px rgba(255, 255, 255, 0.7), inset 10px 10px 10px rgba(174, 174, 192, 0.3);

        &:after {
            transform: translateY(-50%) scale(0.95);
        }
    }
}

.section {
    position: relative;
    margin-top: 85px !important;

    @media screen and (min-width: 320px) {
        margin-top: 30px !important;
    }
}

.section-headline {
    position: absolute;
    top: -70px;
    left: -222px;

    @media screen and (min-width: 1640px) {
        left: -159px;
    }

    @media screen and (min-width: 1440px) {
        top: -81px;
        left: -231px;
        width: 869px;
    }

    @media screen and (min-width: 1024px) {
        top: 0px;
        left: -187px;
        width: 718px;
        height: 404px;
    }

    @media screen and (min-width: 768px) {
        width: 568px;
        height: 262px;
        top: 26px;
        left: -155px;
    }

    @media screen and (min-width: 320px) {
        width: 362px;
        height: 190px;
        top: -3px;
        left: -85px;
    }
}

.section-number {
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 900;
        font-size: 144px;
        line-height: 216px;
        color: $substrate;
        opacity: 0.7;
        text-shadow: -13px -10px 25px #FFFFFF, 10px 10px 25px rgba(174, 174, 192, 0.5);
        counter-increment: section;
        content: "0 " counter(section);

        @media screen and (min-width: 1640px) {
            right: 56px;
        }

        @media screen and (min-width: 1440px) {
            top: 54px;
            right: 8px;
            font-size: 144px;
        }

        @media screen and (min-width: 1024px) {
            top: 152px;
            right: 12px;
            font-size: 100px;
        }

        @media screen and (min-width: 768px) {
            top: 109px;
            right: 2px;
            font-size: 90px;
        }

        @media screen and (min-width: 320px) {
            top: 71px;
            right: 16px;
            font-size: 54px;
            line-height: 86px;
        }
    }
}

.about {
    padding-top: 213px;

    @media screen and (min-width: 1640px) {
        padding-top: 174px;
    }

    @media screen and (min-width: 1440px) {
        padding-top: 211px;
    }

    @media screen and (min-width: 1024px) {
        padding-top: 330px;
    }

    @media screen and (min-width: 768px) {
        padding-top: 298px;
    }

    @media screen and (min-width: 320px) {
        padding-top: 180px;
    }

    .about-text {
        display: block;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: 0.05em;
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 50px;
        }

        @media screen and (min-width: 1640px) {
            line-height: 44px;
        }

        @media screen and (min-width: 1440px) {
            font-size: 24px;
            line-height: 43px;
        }

        @media screen and (min-width: 1024px) {
            font-size: 22px;
            line-height: 40px;
        }

        @media screen and (min-width: 768px) {
            font-size: 20px;
            line-height: 34px;

            &:not(:last-child) {
                margin-bottom: 38px;
            }
        }

        @media screen and (min-width: 320px) {
            font-size: 12px;
            line-height: 21px;

            &:not(:last-child) {
                margin-bottom: 27px;
            }
        }
    }
}

.features {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        margin-top: 134px;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row;
        margin-top: 146px;
    }

    @media screen and (min-width: 320px) {
        flex-direction: column;
        margin-top: 50px;
    }

    .feature {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;

        @media screen and (min-width: 768px) {
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: 320px) {
            &:not(:last-child) {
                margin-bottom: 60px;
            }
        }
    }

    .feature__text {
        margin-top: 50px;
        font-family: Montserrat;
        font-size: 18px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #636369;

        @media screen and (min-width: 1640px) {
            width: 200px;
            line-height: 28px;
        }

        @media screen and (min-width: 1024px) {
            margin-top: 34px;
            width: 244px;
            font-size: 18px;
            line-height: 26px;
        }

        @media screen and (min-width: 768px) {
            margin-top: 34px;
            width: 180px;
            font-size: 16px;
            line-height: 21px;
        }

        @media screen and (min-width: 320px) {
            margin-top: 7px;
            width: 140px;
            font-size: 12px;
        }
    }

    .feature__img {
        @media screen and (min-width: 1640px) {
            width: 180px;
            height: 180px;
        }

        @media screen and (min-width: 1440px) {
            width: 150px;
            height: 150px;
        }

        @media screen and (min-width: 1024px) {
            width: 120px;
            height: 120px;
        }

        @media screen and (min-width: 768px) {
            width: 100px;
            height: 100px;
        }

        @media screen and (min-width: 320px) {
            width: 81px;
        }
    }
}

.feature__img--black {
    display: none;
}

.important {
    position: relative;
    padding: 50px 250px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #FFFFFF;
    z-index: 7;

    @media screen and (min-width: 1640px) {
        padding: 46px 280px;
    }

    @media screen and (min-width: 1440px) {
        padding: 46px 255px;
        font-size: 24px;
        line-height: 37px;
    }

    @media screen and (min-width: 1024px) {
        padding: 46px 155px;
        font-size: 20px;
        line-height: 23px;
    }

    @media screen and (min-width: 768px) {
        padding: 46px 90px;
        font-size: 20px;
        line-height: 23px;
    }

    @media screen and (min-width: 320px) {
        padding: 22px;
        font-size: 12px;
        line-height: 14px;
    }

    &.important--ru {
        background: rgba(47, 128, 237, 0.5);
    }
}

.down-line-right {
    position: absolute;
    right: -110px;
    bottom: -95px;
    z-index: 1;

    @media screen and (min-width: 1640px) {
        right: -106px;
    }

    @media screen and (min-width: 1440px) {
        width: 483px;
        height: 335px;
        right: -168px;
        bottom: -70px;
    }

    @media screen and (min-width: 1024px) {
        width: 284px;
        height: 221px;
        right: -73px;
    }

    @media screen and (min-width: 320px) {
        width: 99px;
        height: 122px;
        right: 1px;
        bottom: 2px;
    }
}

.down-line-left {
    position: absolute;
    left: -110px;
    bottom: -95px;
    z-index: 2;

    @media screen and (min-width: 1640px) {
        left: -62px;
    }

    @media screen and (min-width: 1440px) {
        width: 503px;
        height: 526px;
        left: -104px;
        bottom: -70px;
    }

    @media screen and (min-width: 1024px) {
        width: 284px;
        height: 221px;
        left: -71px;
        bottom: 2px;
    }

    @media screen and (min-width: 320px) {
        width: 99px;
        height: 122px;
        left: -4px;
        bottom: 2px;
    }
}

.block-services {
    padding-top: 200px;

    @media screen and (min-width: 1640px) {
        padding-top: 244px;
    }

    @media screen and (min-width: 1440px) {
        padding-top: 264px;
    }

    @media screen and (min-width: 1024px) {
        padding-top: 279px;
    }

    @media screen and (min-width: 768px) {
        padding-top: 268px;
    }

    @media screen and (min-width: 320px) {
        padding-top: 180px;
    }
}

.services {
    padding-bottom: 20px;

    .services__list {
        list-style-type: none;
    }

    .services__item {
        position: relative;
        padding-left: 72px;
        font-size: 18px;
        letter-spacing: 0.05em;
        color: #636369;

        @media screen and (min-width: 1640px) {
            padding-left: 36px;

            &:not(:last-child) {
                margin-bottom: 51px;
            }
        }

        @media screen and (min-width: 1440px) {
            padding-left: 75px;
        }

        @media screen and (min-width: 1024px) {
            width: unset;
            padding-left: 110px;
            font-size: 18px;
            line-height: 21px;
        }

        @media screen and (min-width: 768px) {
            width: 500px;
            padding-left: 145px;
            font-size: 16px;
            line-height: 21px;

            &:not(:last-child) {
                margin-bottom: 49px;
            }
        }

        @media screen and (min-width: 320px) {
            width: 218px;
            padding-left: 60px;
            font-size: 12px;
            line-height: 16px;

            &:not(:last-child) {
                margin-bottom: 34px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 53px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 20px;
            border: 1px solid $violet;
            filter: drop-shadow(0px 0px 7px $violet);
            border-radius: 50px;
            transform: translateY(-50%);

            @media screen and (min-width: 1640px) {
                left: -17px;
            }

            @media screen and (min-width: 1440px) {
                left: 22px;
            }

            @media screen and (min-width: 1024px) {
                left: 56px;
                width: 20px;
                height: 20px;
            }

            @media screen and (min-width: 768px) {
                left: 93px;
                width: 15px;
                height: 15px;
            }

            @media screen and (min-width: 320px) {
                left: 35px;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.projects {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (min-width: 1440px) {
        flex-direction: row;
    }

    @media screen and (min-width: 320px) {
        flex-direction: column;
    }

    .project {
        position: relative;
        margin-bottom: 90px;
        width: 400px;
        height: 200px;
        border-radius: 5px;
        background: $substrate;
        box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.5);
        overflow: hidden;
        transition: .3s ease all;

        @media screen and (min-width: 1440px) {
            &:nth-child(odd) {
                margin-right: 90px;
            }
        }

        @media screen and (min-width: 1024px) {
            margin-bottom: 90px;
            width: 400px;
            height: 200px;
        }

        @media screen and (min-width: 768px) {
            width: 320px;
            height: 160px;
        }

        @media screen and (min-width: 320px) {
            width: 200px;
            height: 100px;
            margin-bottom: 50px;

            &:nth-child(odd) {
                margin-right: 0;
            }
        }

        &:nth-child(odd) {
            margin-right: 90px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(240, 240, 243, 0.89);
            transition: .2s ease all;
            transform: translateY(-500px);
        }

        &:hover {
            .project__description {
                transform: translate(-50%, -50%);
            }

            &:before {
                transform: translateY(0);
            }

            .project__button {
                display: block;
            }
        }

        &.project--soon {
            &:after {
                content: "Скоро..";
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 18px;
                font-weight: 600;
                color: $gray-middle;
                transform: translate(-50%, -50%);

                @media screen and (min-width: 1024px) {
                    font-size: 18px;
                }
        
                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
        
                @media screen and (min-width: 320px) {
                    font-size: 12px;
                }
            }
        }
    }

    .project__description {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $gray-middle;
        transform: translate(-50%, -500px);
        transition: .3s ease all;

        @media screen and (min-width: 1024px) {
            font-size: 18px;
        }

        @media screen and (min-width: 768px) {
            font-size: 16px;
        }

        @media screen and (min-width: 320px) {
            font-size: 12px;
        }
    }

    .project__image {
        width: 400px;
        height: 200px;
        object-fit: cover;

        @media screen and (min-width: 1024px) {
            width: 400px;
            height: 200px;
        }

        @media screen and (min-width: 768px) {
            width: 320px;
            height: 160px;
        }

        @media screen and (min-width: 320px) {
            width: 200px;
            height: 100px;
        }
    }

    .project__button {
        position: absolute;
        right: -77.5px;
        bottom: -77.5px;
        width: 155px;
        height: 155px;
        border: none;
        border-radius: 100px;
        background-color: $substrate;
        box-shadow: -10px -10px 20px #FFFFFF, 10px 10px 20px rgba(174, 174, 192, 0.3);
        display: none;
        transition: .3s ease all;
        z-index: 4;

        @media screen and (min-width: 768px) {
            right: -77.5px;
            bottom: -77.5px;
            width: 155px;
            height: 155px;
        }

        @media screen and (min-width: 320px) {
            right: -40px;
            bottom: -40px;
            width: 80px;
            height: 80px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 32px;
            left: 42px;
            width: 0; 
            height: 0; 
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 24px solid $substrate;;
            filter: drop-shadow(0 0 4px $violet);
            transition: .3s ease all;

            @media screen and (min-width: 768px) {
                top: 32px;
                left: 42px;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-left: 20px solid $substrate;
            }

            @media screen and (min-width: 320px) {
                top: 16px;
                left: 20px;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-left: 12px solid $substrate;
            }
        }
    }
}

.page-dark {
    background: $black;
    color: rgba(255, 255, 255, 0.6);
    transition: .3s ease all;

    header {
        box-shadow: 0px 1px 20px 20px $black;
        backdrop-filter: blur(10px);
        background-color: $black;
    }

    .breadcrumbs__link {
        color: rgba(255, 255, 255, 0.6);
    }

    .hamburger {
        background: $black;
        box-shadow: -5px -5px 15px rgba(56, 56, 56, 0.7), 5px 5px 15px rgba(0, 0, 0, 0.6);

        &:focus {
            box-shadow: inset -10px -10px 10px rgba(56, 56, 56, 0.6), inset 10px 10px 10px rgba(0, 0, 0, 0.3);
        }
    }

    .hamburger__line {
        background-color: rgba(255, 255, 255, 0.8);
    }

    .menu {
        @media screen and (min-width: 320px) and (max-width: 768px) {
            background: $black;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        }
    }

    .menu__link {
        color: rgba(255, 255, 255, 0.6) !important;

        &:hover {
            background: $black !important;
            box-shadow: -7px -7px 20px #383838, 7px 7px 20px rgba(0, 0, 0, 0.5) !important;
        }

        &:focus {
            box-shadow: inset -10px -10px 10px rgba(56, 56, 56, 0.6), inset 10px 10px 10px rgba(0, 0, 0, 0.3) !important;
        }
    }

    .ss-main {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .change-theme,
    .accessibility {
        background: $black;
        box-shadow: -10px -10px 15px rgba(56, 56, 56, 0.7), 10px 10px 15px rgba(0, 0, 0, 0.4);

        &:hover {
            box-shadow: inset -10px -10px 10px rgba(56, 56, 56, 0.6), inset 10px 10px 10px rgba(0, 0, 0, 0.3);
        }
    }

    .change-theme {
        .moon {
            display: none;
        }

        .sun {
            display: block !important;
            animation: rotation-ease 1s 1 linear;
            animation-fill-mode: forwards;
        }
    }

    .accessibility {
        .eye {
            display: none;
        }

        .eye-black {
            display: block;
        }

        &:hover {
            .eye-black {
                display: none;
            }

            .eye-black-big {
                display: block;
            }
        }
    }

    .show-portfolio {
        background: $black;
        box-shadow: -10px -10px 30px rgba(56, 56, 56, 0.7), 10px 10px 30px #000000;

        &:focus {
            box-shadow: inset -10px -10px 10px rgba(56, 56, 56, 0.6), inset 10px 10px 10px rgba(0, 0, 0, 0.3);
        }

        &:after {
            border-left-color: $black !important;
        }
    }

    .section-number {
        &:before {
            color: $black;
            text-shadow: -10px -10px 30px rgba(56, 56, 56, 0.7), 10px 10px 30px #000000;
        }
    }

    .input-container {
        input {
            color: #FFFFFF;
            caret-color: rgba(255, 255, 255, 0.6);

            &:focus {
                +.title-input {
                    background-color: $black;
                }
            }

            &:not(:placeholder-shown) {
                +.title-input {
                    background-color: $black;
                }
            }
        }

        .title-input {
            color: rgba(255, 255, 255, 0.6) !important;
        }
    }

    .textarea-container {
        textarea {
            color: #FFFFFF;
            caret-color: rgba(255, 255, 255, 0.6);

            &:focus {
                +.title-input {
                    background-color: $black;
                }
            }

            &:not(:placeholder-shown) {
                +.title-input {
                    background-color: $black;
                }
            }
        }

        .title-input {
            color: rgba(255, 255, 255, 0.6) !important;
        }
    }

    .formLight {
        display: none;
    }

    .formBlack {
        display: block;
    }

    .it2b-logo--light {
        display: none;
    }

    .it2b-logo--black {
        display: block;
    }

    .img-circle-i--light {
        display: none !important;
    }

    .img-circle-i--black {
        display: block !important;
    }

    .feature__img--light {
        display: none;
    }

    .feature__img--black {
        display: block;
    }

    .feature__text {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .services__item {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .headline-primary {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .form {
        background: $black;
        box-shadow: -10px -10px 30px rgba(56, 56, 56, 0.4), 10px 10px 40px rgba(0, 0, 0, 0.7);
    }

    .form__title {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .form__text {
        color: rgba(255, 255, 255, 0.6) !important;
    }

    .ss-content {
        box-shadow: -10px -10px 30px rgba(56, 56, 56, 0.7), 10px 10px 30px #000000;
    }

    .ss-content .ss-list .ss-option.ss-disabled {
        background-color: #393939;
    }

    .project {
        background-color: $black;
        box-shadow: -10px -10px 30px #000000, 10px 10px 30px rgba(0, 0, 0, 0.5);

        &:before {
            background: rgba(37, 37, 37, 0.9);
        }

        &.project--soon {
            &:after {
                color: rgba(255, 255, 255, 0.6) !important;
            }
        }
    }

    .project__description {
        color: rgba(255, 255, 255, 0.6);
    }

    .project__button {
        background-color: $black;
        box-shadow: -10px -10px 30px rgba(56, 56, 56, 0.7), 10px 10px 30px #000000;

        &:before {
            border-left-color: $black !important;
        }
    }
}