.ss-main {
    width: 120px !important;

    @media screen and (min-width: 1024px) {
        width: 90px !important;
        font-size: 14px;
    }

    @media screen and (min-width: 768px) {
        width: 90px !important;
        font-size: 14px;
    }

    @media screen and (min-width: 320px) {
        width: 90px !important;
        font-size: 14px;
    }
}

.ss-main .ss-single-selected {
    border: none !important;
    background-color: transparent !important;
}

.ss-content {
    border: none !important;
    background-color: transparent !important;
    backdrop-filter: blur(10px);
    box-shadow: -10px -10px 20px #FFFFFF, 10px 10px 20px rgba(174, 174, 192, 0.5);
}

.ss-content .ss-search {
    display: none !important;
}

.ss-content .ss-list {
    backdrop-filter: blur(10px);
}

.ss-content .ss-list .ss-option.ss-disabled {
    color: #999999;
    background-color: #fafafa;
}

.ss-content .ss-list .ss-option.ss-highlighted, .ss-content .ss-list .ss-option:hover {
    background-color: #e7e7e7 !important;
}